import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const links = [
  {
    'title': 'Privacy Complaint Form',
    'href': '/privacy-complaint',
  },
  {
    'title': 'Incident Notification Form',
    'href': '/incident-notification',
  },
  {
    'title': 'FOI Complaint Form',
    'href': '/foi-complaint',
  },
  {
    'title': 'FOI Review Form',
    'href': '/foi-review',
  },
];

const IndexPage = () => (
  <Layout
    title="OVIC Forms">
    <ul>
      {links.map((link, index) => (
        <li key={index}>
          <Link to={link.href}>
            {link.title}
          </Link>
        </li>
      ))}
    </ul>
  </Layout>
)

export default IndexPage;
